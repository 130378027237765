import directory from '@tutorbot/directory-api';
import api from './api';

export const sortingKeys = ['relevance', 'near', 'priceLow'];

export const getCourses = async ({
  filters: formatted = {},
  limit = 6,
  page = 1,
  markers = false,
  verified = true,
  userTrackingId,
}) => {
  const filters = JSON.parse(JSON.stringify(formatted));

  if (verified !== 'all') {
    filters.verified = verified;
  } else {
    delete filters.verified;
  }

  return await api.post('courses', {
    filters,
    limit,
    page,
    markers,
    userTrackingId,
  });
};

export const getCourseByShortId = async (shortId, { countries = [] }) => {
  const queryString = countries.length > 0 ? `?country=${countries[0]}` : '';
  return await api.get(`courses/${shortId}/${queryString}`);
};

export const getCourseByShortIdWizard = async shortId => await api.get(`courseWizard/${shortId}`);

export const getSchoolByShortId = async (shortId, { countries = [] }) =>
  await api.get(`schools/${shortId}`, {
    countries,
  });

export const getSchoolByShortIdWizard = async shortId => await api.get(`schoolWizard/${shortId}`);

export const toggleFavourite = async (shortId, isFavourite, type) =>
  await api.post('/users/favourites', {
    shortId,
    isFavourite,
    type,
  });

export const getArticles = async ({
  categories = [],
  limit = null,
  where = {},
  excludeCategories = [],
}) => {
  const { blog } = directory();
  Object.keys(where).forEach(key => {
    blog.where(key, where[key]);
  });

  if (Array.isArray(categories) && categories.length) {
    categories.forEach(category => blog.where('categories', category));
  }

  if (Array.isArray(excludeCategories) && excludeCategories.length) {
    excludeCategories.forEach(category => blog.whereNot('categories', category));
  }

  if (limit) {
    blog.limit(limit);
  }
  return await blog.get();
};

export const getArticleByUrl = async (shortId, categories = []) => {
  const articles = await getArticles({ where: { shortId }, categories });
  return articles.total ? articles.data[0] : null;
};

export const getAboutArticle = async (blogCountry, blogLocale) => {
  const articles = await getArticles({
    categories: [blogCountry, blogLocale, 50],
    excludeCategories: [52],
    limit: 1,
  });
  return articles.total ? articles.data[0] : null;
};

export const getCareersArticle = async (blogCountry, blogLocale) => {
  const articles = await getArticles({
    categories: [blogCountry, blogLocale, 95],
  });
  return articles.total ? articles.data : null;
};

const getArticleWithKeys = keys => async (blogCountry, blogLocale) => {
  const articles = await getArticles({ categories: [blogCountry, blogLocale, ...keys], limit: 1 });
  return articles.total ? articles.data[0] : null;
};

export const getTermsArticle = getArticleWithKeys([65]);
export const getPricingArticle = getArticleWithKeys([93]);
export const getFeaturesArticle = getArticleWithKeys([96]);
export const getContactUsArticle = getArticleWithKeys([97]);
export const getOurCommunitiesArticle = getArticleWithKeys([98]);
export const getPrivacyArticle = getArticleWithKeys([99]);
export const getAddCourseIntroArticle = getArticleWithKeys([100]);
