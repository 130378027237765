import React from 'react';
import PropTypes from 'prop-types';
import router, { getRoute, getMatchingRoute } from '../routes';

export const RouteContext = React.createContext();

export const RouteProvider = ({ children, page, params, queryParams }) => {
  const [state, setState] = React.useState({
    page,
    params,
    queryParams,
  });
  const [loading, setLoading] = React.useState(false);

  if (
    state.page !== page ||
    JSON.stringify(state.params) !== JSON.stringify(params) ||
    JSON.stringify(state.queryParams) !== JSON.stringify(queryParams)
  ) {
    setState({
      ...state,
      page,
      params,
      queryParams,
    });
  }

  const routeChangeStartHandler = () => {
    setLoading(true);
  };

  const routeChangeEndHandler = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeEndHandler);
  }, []);

  return <RouteContext.Provider value={{ ...state, loading }}>{children}</RouteContext.Provider>;
};

export const withRoute = WrappedComponent =>
  class extends React.PureComponent {
    render() {
      return (
        <RouteContext.Consumer>
          {props => <WrappedComponent {...props} {...this.props} />}
        </RouteContext.Consumer>
      );
    }
  };

RouteProvider.propTypes = {
  children: PropTypes.any,
  page: PropTypes.string,
  params: PropTypes.object,
  queryParams: PropTypes.object,
};

RouteProvider.defaultProps = {
  children: null,
  page: null,
  params: {},
  queryParams: {},
};

RouteProvider.getInitialProps = async ctx => {
  const route = getMatchingRoute(ctx.asPath);
  route.page = route.page ? route.page.replace(/[^\w\s!?]/g, '') : null;
  route.queryParams = {};

  // Route queryParams fix
  if (route.page) {
    const { pattern } = getRoute(route.page);

    const paramsKeys = Object.keys(route.params);
    const filteredKeys = paramsKeys.filter(key => pattern.indexOf(`:${key}`) !== -1);

    paramsKeys.forEach(key => {
      if (!filteredKeys.includes(key)) {
        route.queryParams[key] = route.params[key];
        delete route.params[key];
      }
    });
  }

  return {
    ...route,
  };
};
